import { Box, Typography } from "@mui/material";
import MeowToken from "./components/MeowToken";
import ChestInfo from "./components/ChestInfo";
import { useSelector } from "react-redux";
import { IReduxState } from "../../store/slices/state.interface";
import HoneyPot from "assets/images/honeypot.png";

const Stake = () => {
  const JackpotAmount = useSelector<IReduxState, string>(
    (state) => state.jackpot.jackpotAmount
  );

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      sx={{ backgroundColor: "#111B24", py: { xs: 2, sm: 4, md: 6, xl: 8 } }}
    >
      <Box
        display='flex'
        alignItems='center'
        sx={{
          backgroundColor: "#393D32",
          width: { xs: "320px", sm: "540px", md: "800px", lg: '900px', xl: "1200px" },
        }}
      >
        <Box
          component='img'
          src={HoneyPot}
          alt='Honey Pot'
          width='10%'
        />
        <Typography
          fontFamily="Audiowide"
          sx={{
            color: "#ffc90e",
            fontSize: { xs: "12px", sm: "20px", md: "30px", lg: '36px', xl: "40px" },
            py: 1,
            px: { xs: 1, sm: 3 },
            textAlign: "center",
            mx: "auto",
          }}
        >
          Honey Pot Jackpot: {JackpotAmount ? JackpotAmount : 0} Tron
        </Typography>
        <Box
          component='img'
          src={HoneyPot}
          alt='Honey Pot'
          width='10%'
        />
      </Box>
      <MeowToken />
      <ChestInfo />
    </Box>
  );
};

export default Stake;
