import { Box } from "@mui/material";
import "./banner.scss";
import BannerImage1 from "assets/images/banner.png";

const Banner = () => {
  return (
    <>
      <Box className="banner-container">
        <Box
          component="img"
          sx={{
            width: { xs: "90%", sm: "70%" },
            mr: { xs: 1, sm: 2, md: 3 },
          }}
          src={BannerImage1}
          alt="Banner Image"
        />
      </Box>
    </>
  );
};

export default Banner;
