export const Datas = [
  {
    roomNum: 1,
    firstNFT: "",
    secondNFT: "",
    firstAddress: "",
    secondAddress: "",
    nftAddress: "",
    nftName: "",
    firstRandom: 0,
    secondRandom: 0,
    tokenId: 0,
    fightRoom: 0,
    whichfight: 0,
  },
  {
    roomNum: 2,
    firstNFT: "",
    secondNFT: "",
    firstAddress: "",
    secondAddress: "",
    nftAddress: "",
    nftName: "",
    firstRandom: 0,
    secondRandom: 0,
    tokenId: 0,
    fightRoom: 0,
    whichfight: 0,
  },
  {
    roomNum: 3,
    firstNFT: "",
    secondNFT: "",
    firstAddress: "",
    secondAddress: "",
    nftAddress: "",
    nftName: "",
    firstRandom: 0,
    secondRandom: 0,
    tokenId: 0,
    whichfight: 0,
    fightRoom: 0,
  },
  {
    roomNum: 4,
    firstNFT: "",
    secondNFT: "",
    firstAddress: "",
    secondAddress: "",
    nftAddress: "",
    nftName: "",
    firstRandom: 0,
    secondRandom: 0,
    tokenId: 0,
    fightRoom: 0,
    whichfight: 0,
  },
  {
    roomNum: 5,
    firstNFT: "",
    secondNFT: "",
    firstAddress: "",
    secondAddress: "",
    nftAddress: "",
    nftName: "",
    firstRandom: 0,
    secondRandom: 0,
    tokenId: 0,
    fightRoom: 0,
    whichfight: 0,
  },
  {
    roomNum: 6,
    firstNFT: "",
    secondNFT: "",
    firstAddress: "",
    secondAddress: "",
    nftAddress: "",
    nftName: "",
    firstRandom: 0,
    secondRandom: 0,
    tokenId: 0,
    fightRoom: 0,
    whichfight: 0,
  },
  {
    roomNum: 7,
    firstNFT: "",
    secondNFT: "",
    firstAddress: "",
    secondAddress: "",
    nftAddress: "",
    nftName: "",
    firstRandom: 0,
    secondRandom: 0,
    tokenId: 0,
    fightRoom: 0,
    whichfight: 0,
  },
  {
    roomNum: 8,
    firstNFT: "",
    secondNFT: "",
    firstAddress: "",
    secondAddress: "",
    nftAddress: "",
    nftName: "",
    firstRandom: 0,
    secondRandom: 0,
    tokenId: 0,
    fightRoom: 0,
    whichfight: 0,
  },
];
