import { setAll } from "helpers/set-all";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "state";
import instance from "constants/axios";
import { userinfoDataStyle } from "@types";

export const loadUserInfoDetails = createAsyncThunk(
  "userinfo/loadUserInfoDetails",
  async ({ userInfoData }: IUserInfoSlice) => {
    await instance
      .get("/api/userinfo")
      .then((response) => {
        if(response.data[0]) {
          userInfoData = response.data[0];
        } else {
          userInfoData = {
            address: undefined,
            stakeAmount: 0,
            claimAmount: 0,
            ownNfts: []
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return {
      userInfoData,
    };
  }
);

const initialState: {
  loading: boolean;
  userInfoData: userinfoDataStyle;
} = {
  loading: true,
  userInfoData: {
    address: undefined,
    stakeAmount: 0,
    claimAmount: 0,
    ownNfts: []
  },
};

export interface IUserInfoSlice {
  userInfoData: userinfoDataStyle;
}

const userinfoSlice = createSlice({
  name: "userinfo",
  initialState,
  reducers: {
    updateUserInfoData(state, action) {
      let updatedData: userinfoDataStyle = action.payload;
      
      state.userInfoData = updatedData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUserInfoDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadUserInfoDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadUserInfoDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default userinfoSlice.reducer;

export const { updateUserInfoData } = userinfoSlice.actions;

export const getAppState = createSelector(baseInfo, (app) => app);
