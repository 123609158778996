import { Box, Button, Typography } from "@mui/material";
import { IReduxState } from "store/slices/state.interface";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { useWeb3React } from "@web3-react/core";
import { claimMoney, widrawNFT } from "store/slices/play-slice";
import { useCallback, useEffect } from "react";
import { walletInfo } from "store/slices/walletInfo-slice";
import React from "react";

const availableStyle = {
  paddingY: "4px",
  textAlign: "center",
  fontSize: "18px",
  color: "#F39B33",
};

const Jackpot = () => {
  const { account } = useWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  const JackpotAmount = useSelector<IReduxState, string>(
    (state) => state.jackpot.jackpotAmount
  );
  let contractNFTs: number = useSelector<IReduxState, number>(
    (state) => state.userInfo.userInfoData.ownNfts.length
  );
  let widrawAmount: number = useSelector<IReduxState, number>(
    (state) => state.userInfo.userInfoData.claimAmount
  );

  const getAvailableData = useCallback(() => {
    dispatch(walletInfo({ account: account }));
  }, [account]);

  const onWidrawNFT = useCallback(async () => {
    let widrawState = await dispatch(widrawNFT({ address: account }));
    if (widrawState.meta.requestStatus === "fulfilled") {
      getAvailableData();
    }
  }, [account]);

  const onClaimMoney = useCallback(async () => {
    let claimState = await dispatch(claimMoney({ address: account }));
    if (claimState.meta.requestStatus === "fulfilled") {
      getAvailableData();
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      getAvailableData();
    }
  }, [account]);

  

  return (
    <Box
      sx={{
        backgroundColor: "rgba(230,239,237, 0.1)",
        paddingY: "3px",
        position: "relative",
      }}
    >
      <Typography
        fontFamily="Audiowide"
        sx={{
          color: "#ffc90e",
          fontSize: { xs: "20px", sm: '32px', md: "48px" },
          fontWeight: '700',
          py: 1,
          px: { xs: 1, sm: 3 },
          backgroundColor: "rgba(38,40,42,0.64)",
          textAlign: "center",
          mx: "auto",
        }}
      >
        Honey Pot Jackpot: {JackpotAmount} TRX
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "self-start",
          pb: 6,
          mt: "12px",
        }}
      >
        <Box sx={{ mr: 3, textAlign: "center" }}>
          <Button variant="contained" color="info" onClick={onWidrawNFT}>
            Withdraw NFTs
          </Button>
          <Typography sx={availableStyle}>
            Available NFTs: {contractNFTs ? contractNFTs : 0} NFTs
          </Typography>
        </Box>
        <Box sx={{ mr: 3, textAlign: "center" }}>
          <Button variant="contained" color="info" onClick={onClaimMoney}>
            Withdraw TRX
          </Button>
          <Typography sx={availableStyle}>
            Available TRX: {widrawAmount ? widrawAmount : 0} TRX
          </Typography>
        </Box>
        {/* <Box sx={{ mr: 3, textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleClickStake("stake");
            }}
          >
            Meow Staking
          </Button>
        </Box> */}
      </Box>
      <Box
        display='flex'
        alignItems='center'
        sx={{
          justifyContent: {xs: 'center', sm: 'space-between'},
          flexDirection: { xs: 'column', sm: 'row'},
        }}
      >
        <Box
          sx={{
            mr: {xs: 0, sm: 3},
            mt: {xs: 1, sm: 0},
            mb: {xs: 1, sm: 0},
          }}
          display='flex'
          justifyContent='center'
        >
          <Typography
            fontFamily="Audiowide"
            sx={{
              color: "#fff",
              fontSize: { xs: "20px", md: "36px" },
              py: 1,
              px: { xs: 1, xl: 2 },
              backgroundColor: "rgba(38,40,42,0.64)",
              width: { xs: "320px", sm: '480px', md: "720px", xl: "1000px" },
              textAlign: "center",
            }}
          >
            HNY left to be mined: 99,999,980
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Jackpot;
